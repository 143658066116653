import React from "react"
import App from "../components/App"
import '../components/About.css';
import Helmet from "react-helmet"

export default function About() {
  return (
    <App>

      <Helmet>
        <title>About CoDreams</title>
        <meta name="description" content="Key objective of CoDreams Health website is to build a channel for open availability of health-related information. Pulling the knowledge from the silos of health care professionals, researchers & medical institutes and channelising it towards the general masses." />
        <meta name="keywords" content="objective, why, workflow" />
        <meta property="og:title" content="About CoDreams" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Key objective of CoDreams Health website is to build a channel for open availability of health-related information. Pulling the knowledge from the silos of health care professionals, researchers & medical institutes and channelising it towards the general masses." />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://codreams.org" />
        <link rel="canonical" href="https://codreams.org" />
      </Helmet>

      <div className='right-frame'>
        <div className='about_page'>
			<div className='about_card'>
        		<h1>About</h1>
        		<h3 className='about_heading'>Why CoDreams Health Website</h3>
          		<div className='about_item'>
          			<div className='img_wrapper'>
          				<p>&nbsp;</p>
              			<img src='/why.jpg' className='about_img' alt="Why CoDreams"></img>
              		</div>
            		<ul className='about_list'>
							<li>A lot of information on healthy diet & lifestyle is limited to nutritionists engaged with celebrities & athletes</li>
    						<li>Many articles are published in medical journals of modern medicine which mention research done on benefits of different diets but at present there has been a lack of efficient channels to make this information available to the masses</li>
    						<li>Many articles in medical journals endorse the nutrition guidelines mentioned in Ayurveda but there is lack of awareness of this information in common life</li>
    						<li>CoDreams Health Website is committed to the purpose of making this information available to the general masses, such that good health can be maintained with nutrition in diet & a balanced lifestyle</li>
    				</ul>
          		</div>
        		<p>&nbsp;</p>
        		<p>&nbsp;</p>
        		<h3 className='about_heading'>Objective of CoDreams Health Website</h3>
          		<div className='about_item'>
          			<div className='img_wrapper'>
          				<p>&nbsp;</p>
              			<img src='/objective.jpg' className='about_img' alt="Objective od CoDreams"></img>
              		</div>
            		<ul className='about_list'>
							<li>Key objective of CoDreams Health website is to build a channel for open availability of health-related information</li>
    						<li>Pulling the knowledge from the silos of health care professionals, researchers & medical institutes and channelising it towards the general masses</li>
    						<li>General masses usually make choices based on ad campaigns & articles published in mainstream media which in many cases are biased towards promotion of few products & treatments</li>
    						<li>CoDreams Health portal has vision to help the general masses with correct & verified health-related information, such that the diet & lifestyle related diseases could not just be controlled but also could be avoided altogether</li>
    				</ul>
          		</div>
        		<p>&nbsp;</p>
        		<p>&nbsp;</p>
          		<h3 className='about_heading'>How CoDreams Health Website works</h3>
        		<div className='about_item'>
        			<div className='img_wrapper'>
        				<p>&nbsp;</p>
              			<img src='/workflows.jpg' className='about_img' alt="Workflow of CoDreams Website"></img>
              		</div>
            		<ul className='about_list'>
							<li>Health care professionals, Researchers & Professors can register with CoDreams Health portal by sending their details to contributors@codreams.org</li>
    						<li>Contributors can submit their articles to editor@codreams.org</li>
    						<li>After editorial review the article will be published on the portal</li>
    						<li>Users can directly access the articles hosted on the portal</li>
    						<li>Apart from health articles, some research work is also published which captures insights on items of day-to-day use, from a common man perspective</li>
    						<li>In future, few health related tools will also be added to the portal</li>
    				</ul>
          		</div>

        	</div>
      	</div>
      </div>
    </App>
  )  
}